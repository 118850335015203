// Navtoggler Logic
document.querySelector('.headerbar__navtoggler').addEventListener('click', () => {
  document.body.classList.toggle('body--activenav');
})

// Offcanvas Logic
for (let parentNavItem of document.querySelectorAll('.headerbar__nav-nav ul > li.parent')) {
  parentNavItem.querySelector('a, span').addEventListener('click', (e) => {
    if (document.body.clientWidth < 993) {
      e.preventDefault()
    }

    parentNavItem.classList.toggle('opened');
  })
}
// Elementoverlap-Top

let setNegativeMarginTop = (elem) => {
  elem.style.setProperty('--margin', elem.clientHeight + "px");
}

let updateOverlapTopElemPositioning = () => {
  if(window.innerWidth > 992) {
    for (let overlapTopElement of document.querySelectorAll('.elementoverlap-top')) {
      setNegativeMarginTop(overlapTopElement);
    }
  } else {
    for (let overlapTopElement of document.querySelectorAll('.elementoverlap-top')) {
      overlapTopElement.style.setProperty('--margin', 0 + "px");
    }
  }

}
window.addEventListener('DOMContentLoaded', updateOverlapTopElemPositioning);
window.addEventListener('resize', updateOverlapTopElemPositioning);

// Back To Top Button

window.addEventListener('scroll', () => {
  if (window.scrollY > 400) {
    document.querySelector('.footer__backtotop').style.opacity = '1';
  } else {
    document.querySelector('.footer__backtotop').style.opacity = '0';
  }
})

// General Data Togglearea Logic

// Hide all Elements with class togglearea-*

for (let toggleArea of document.querySelectorAll('[class*=togglearea]')) {
  toggleArea.style.display = 'none';
}

for (let toggleButton of document.querySelectorAll('button[data-togglearea]')) {
  toggleButton.addEventListener('click', () => {
    if (document.querySelector('.togglearea-' + toggleButton.dataset.togglearea).style.display == 'inherit') {
      document.querySelector('.togglearea-' + toggleButton.dataset.togglearea).style.display = 'none';
    } else {
      document.querySelector('.togglearea-' + toggleButton.dataset.togglearea).style.display = 'inherit';
    }
  })
}

// Change Demo-Link in Headerbar on Training-Page

let updateHeaderDemoLink = () => {
  if(document.querySelector('.trainingpage')) {
    document.querySelector('.headerbar .demolink').setAttribute('href', document.querySelector('.trainingpage__introarea-content a.btn').attributes.href.nodeValue);
  }
}

window.addEventListener('DOMContentLoaded', updateHeaderDemoLink);

// Toggle Search Filter
window.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.filtermagic-field-filter').forEach((filter, index) => {
    if (!filter || !filter.querySelector('legend')) {
      return
    }

    filter.querySelector('legend').addEventListener('click', () => {
      filter.querySelector('fieldset').classList.toggle('open');
    })
  });
})

// Sync extra search field for mobile
window.addEventListener('DOMContentLoaded', () => {
  if (!document.querySelector('.com-content-category-blog__filters-mobilesearch')) {
    return
  }

  document.querySelector('.com-content-category-blog__filters-mobilesearch').addEventListener('change', () => {
    document.querySelector('#filtermagic_filter_searchtext').value = document.querySelector('.com-content-category-blog__filters-mobilesearch').value
  })

  document.querySelector('#filtermagic_filter_searchtext').addEventListener('change', () => {
    document.querySelector('#com-content-category-blog__filters-mobilesearch').value = document.querySelector('#filtermagic_filter_searchtext').value
  })
});

// Show / Hide Filter on mobile Devices
window.addEventListener('DOMContentLoaded', () => {
  if (!document.querySelector('.com-content-category-blog__filters-toggle')) {
    return
  }

  document.querySelector('.com-content-category-blog__filters-toggle').addEventListener('click', () => {
    document.querySelector('.com-content-category-blog__filters').classList.toggle('com-content-category-blog__filters--active')
  })
});


// Sharing-Toggle
window.addEventListener('DOMContentLoaded', () => {
  for (let dropdown of document.querySelectorAll('.dropdowncontainer')) {
    dropdown.querySelector('.dropdowncontainer__toggle').addEventListener('click', () => {
      dropdown.querySelector('.dropdowncontainer__list').classList.toggle('dropdowncontainer__list--open')
    })
  }
});

// Layout Switch for eTraining-View

let setBlogLayout = () => {
  if (!document.querySelector('#trainingresults .com-content-category-blog__items')) {
    return
  }

  if(sessionStorage.getItem('layout') == 'list') {
    document.querySelector('#trainingresults .com-content-category-blog__items').classList.add('com-content-category-blog__items--list')
    document.querySelector('#com-content-category-blog__viewswitch #list').classList.remove('d-block')
    document.querySelector('#com-content-category-blog__viewswitch #grid').classList.add('d-block')
  } else {
    document.querySelector('#trainingresults .com-content-category-blog__items').classList.remove('com-content-category-blog__items--list')
    document.querySelector('#com-content-category-blog__viewswitch #grid').classList.remove('d-block')
    document.querySelector('#com-content-category-blog__viewswitch #list').classList.add('d-block')
  }
}

window.addEventListener('DOMContentLoaded', () => {
  if (!document.getElementById('com-content-category-blog__viewswitch')) {
    return
  }

  setBlogLayout();

  document.getElementById('com-content-category-blog__viewswitch').addEventListener('click', () => {
    // Toggle Parameter in Session Storage
    if(sessionStorage.getItem('layout') == 'grid') {
      sessionStorage.setItem('layout', 'list');
    } else {
      sessionStorage.setItem('layout', 'grid');
    }
    // Set Blog Layout on Click
    setBlogLayout();
  })
});
